import React, { useMemo } from 'react';
import { cloneDeep } from 'lodash';

import usePostHogFeatureFlag from 'hooks/usePostHogFeatureFlag';
import useContentfulPromotionContent from 'hooks/useContentfulPromotionContent';
import DesktopNavClearance from 'components/nav/DesktopHeader/components/DesktopNavClearance';
import SanitizedHtml from 'components/consumer/SanitizedHtml';
import useProjectSimonConfig from 'data-hooks/useProjectSimonConfig';
import desktopStaticNavItems from './desktopStaticNavItems';
import newDesktopStaticNavItems from './newDesktopStaticNavItems';

const useDesktopNavItems = () => {
  const categoryNavTest = usePostHogFeatureFlag('navbar-categories-vs-rooms');
  const staticItems =
    categoryNavTest === 'test'
      ? newDesktopStaticNavItems
      : desktopStaticNavItems;

  const { promotionData } = useContentfulPromotionContent();

  const { data: projectSimonConfig } = useProjectSimonConfig();

  const {
    isProjectSimonEnabled,
    projectSimonLinkInNavbar,
  } = projectSimonConfig;

  // const hideClearanceFlag = usePostHogFeatureFlag(
  //   'hide-clearance-and-flashSale-menu-item'
  // );

  // const showBlackFridaySalesLinks = !hideClearanceFlag;

  // TODO: Re-enable the clearance feature flag above
  const showBlackFridaySalesLinks = true;

  const categoryNavItems = useMemo(() => {
    const updatedItems = cloneDeep(staticItems);

    const joybirdDesignHouseGroup = updatedItems.find(
      item => item.id === 'joybird-design-house'
    );

    const freeDesignServicesItem = joybirdDesignHouseGroup
      ? joybirdDesignHouseGroup.items.find(
          item => item.id === 'free-design-services'
        )
      : null;

    if (freeDesignServicesItem) {
      freeDesignServicesItem.label = 'Free Design Services';
    }

    const saleCategory = updatedItems.find(({ id }) => id === 'sale');

    if (saleCategory) {
      saleCategory.image =
        promotionData?.saleDropdownMedia || saleCategory.image;
    }

    const flashSaleItem = promotionData?.displayFlashSale
      ? {
          label: 'Flash Sale',
          id: 'flash-sale',
          url: '/flash-sale/',
        }
      : null;

    if (showBlackFridaySalesLinks) {
      saleCategory.url = '/sale/';
      saleCategory.ctaUrl = '/sale/';

      saleCategory.items = [
        {
          label: 'Clearance',
          id: 'clearance',
          url: '/clearance/',
          customContent: <DesktopNavClearance />,
        },
        flashSaleItem,
      ].filter(item => !!item);
    }

    if (
      promotionData?.saleDropdownAuxPromoText &&
      promotionData?.saleDropdownAuxPromoUrl
    ) {
      saleCategory.items = [
        {
          label: (
            <SanitizedHtml
              as="span"
              html={promotionData?.saleDropdownAuxPromoText}
            />
          ),
          id: 'aux-promo-link',
          url: `${promotionData?.saleDropdownAuxPromoUrl}`,
        },
        ...saleCategory.items,
      ];
    }

    if (
      isProjectSimonEnabled &&
      projectSimonLinkInNavbar &&
      projectSimonLinkInNavbar?.desktopIdToReplace
    ) {
      const featuredCategory = updatedItems.find(({ id }) => id === 'featured');

      const itemToReplace = featuredCategory.items.find(
        ({ id }) => id === projectSimonLinkInNavbar?.desktopIdToReplace
      );

      if (itemToReplace) {
        itemToReplace.id = projectSimonLinkInNavbar?.id;
        itemToReplace.image = projectSimonLinkInNavbar?.image;
        itemToReplace.label = projectSimonLinkInNavbar?.label;
        itemToReplace.url = projectSimonLinkInNavbar?.url;
      }
    }

    return updatedItems;
  }, [
    isProjectSimonEnabled,
    projectSimonLinkInNavbar,
    promotionData?.displayFlashSale,
    promotionData?.saleDropdownAuxPromoText,
    promotionData?.saleDropdownAuxPromoUrl,
    promotionData?.saleDropdownMedia,
    showBlackFridaySalesLinks,
    staticItems,
  ]);

  return categoryNavItems;
};

export default useDesktopNavItems;
